import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import Header from "../components/Header";
import ReportNavigation from "./report-navigation/ReportNavigation";
import Report from "src/interface/views/report/Report";
import styles from "./Home.module.css";

const Home: React.FC = () => {
  return (
    <Router>
      <div className={styles["app-container"]}>
        <div className={styles["app-container__header"]}>
          <Header />
        </div>
        <div className={styles["app-container__content"]}>
          <Switch>
            <PrivateRoute
              path="/reports/select-report"
              component={ReportNavigation}
            />
            <PrivateRoute path="/reports/:reportId" component={Report} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default Home;
