import React, { useContext } from "react";
import { LoginContext } from "src/interface/contexts/LoginContext";
import { logoutUser } from "src/interface/auth/auth.service";
import { ErrorDisplay } from "src/interface/components/error/error.interface";
import styles from "src/interface/components/error/Error.module.css";

const Error: React.FC<ErrorDisplay> = (props) => {
  const { setIsLoggedIn } = useContext(LoginContext);

  const isUnauthorized = (): boolean => {
    return props.status === 403 || props.message === "jwt expired";
  };

  const logout = async (): Promise<void> => {
    try {
      await logoutUser();
      setIsLoggedIn(false);
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className={styles["error"]}>
      <h1 className={styles["error__status"]}>{props.status}</h1>
      <span className={styles["error__message"]}>{props.message}</span>
      {isUnauthorized() && (
        <button className={styles["error__button"]} onClick={logout}>
          Login again
        </button>
      )}
    </div>
  );
};

export default Error;
