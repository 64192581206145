import React, { useContext } from "react";
import { ZoomContext } from "src/interface/contexts/ZoomContext";
import { zoomConstants } from "src/interface/components/zoom-slider/zoom-slider.service";
import styles from "src/interface/components/zoom-slider/ZoomSlider.module.css";

const ZoomSlider: React.FC = () => {
  const { zoomValues, setZoomValues } = useContext(ZoomContext);

  const zoom = (e: any) => {
    const currentZoomSliderValue = e.target.value;

    if (
      currentZoomSliderValue &&
      currentZoomSliderValue <= zoomConstants.MAX_ZOOM
    ) {
      const updatedZoomFactor =
        currentZoomSliderValue * zoomConstants.BASE_ZOOM_FACTOR;

      setZoomValues({
        zoomSliderValue: currentZoomSliderValue,
        zoomFactor: updatedZoomFactor,
      });
    }
  };

  return (
    <div className={styles["zoom-slider"]}>
      <input
        type="range"
        name="slider"
        min={`${zoomConstants.MIN_ZOOM}`}
        max={`${zoomConstants.MAX_ZOOM}`}
        value={`${zoomValues.zoomSliderValue}`}
        onChange={(e) => zoom(e)}
      />
    </div>
  );
};

export default ZoomSlider;
