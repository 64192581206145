import React, { useEffect, useState } from "react";
import legend from "src/assets/legend.svg";
import { generateTimeStamp } from "src/interface/components/legend/legend.service";
import { LegendProps } from "src/interface/components/legend/legend.interface";
import styles from "src/interface/components/legend/Legend.module.css";

const Legend: React.FC<LegendProps> = (props) => {
  const [printTimestamp, setPrintTimestamp] = useState<string>("");

  useEffect(() => {
    setPrintTimestamp(generateTimeStamp());

    const timestampInterval = setInterval(() => {
      setPrintTimestamp(generateTimeStamp());
    }, 60 * 1000);

    return () => clearInterval(timestampInterval);
  }, []);

  return (
    <div className={styles["legend"]}>
      <div className={styles["legend__headers"]}>
        <span className={styles["legend__headers__timestamp"]}>
          {printTimestamp}
        </span>
        <span className={styles["legend__headers__subtitle"]}>
          {props.subtitle}
        </span>
      </div>
      <div className={styles["legend__img"]}>
        <img src={legend} alt="Legend" />
      </div>
    </div>
  );
};

export default Legend;
