import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { reports } from "src/data/reports";
import { ReportTilesDataFactory } from "src/interface/views/report-navigation/report-navigation.service";
import { IReportTileData } from "src/interface/components/report-tile/report-tile.interface";
import { ErrorDisplay } from "src/interface/components/error/error.interface";
import ReportTile from "src/interface/components/report-tile/ReportTile";
import LoadingIndicator from "src/interface/components/loading-indicator/LoadingIndicator";
import styles from "src/interface/views/report-navigation/ReportNavigation.module.css";
import Error from "src/interface/components/error/Error";

const ReportNavigation: React.FC = () => {
  const [reportsTileData, setReportsTileData] = useState<IReportTileData[]>([]);
  const [error, setError] = useState<ErrorDisplay>();

  useEffect(() => {
    if (reportsTileData.length > 0) return;
    ReportTilesDataFactory.prepareReportTileData(reports)
      .then(setReportsTileData)
      .catch((error) => {
        setError({
          message: error.message,
          status: error.response.status,
        });
      });
  }, [reportsTileData.length]);

  if (error) {
    return <Error {...error} />;
  }
  return (
    <>
      {reportsTileData.length ? (
        <>
          <div className={styles["report-navigation"]}>
            {ReportTilesDataFactory.sortReports(reportsTileData).map(
              (reportTileData, index) => (
                <Link
                  to={`/reports/${reportTileData.id}`}
                  key={index}
                  className={styles["report-navigation__link"]}
                >
                  <ReportTile {...reportTileData} />
                </Link>
              )
            )}
          </div>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

export default ReportNavigation;
