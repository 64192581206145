import axios, { AxiosResponse } from "axios";

export const getReportsThumbs = (
  previewUrl: string
): Promise<AxiosResponse> => {
  return axios.get(previewUrl, { responseType: "arraybuffer" });
};

export const getReportActivitySvg = (
  activityUrl: string
): Promise<AxiosResponse> => {
  const svgRequestHeaders = {
    headers: { Accept: "image/svg+xml" },
  };
  return axios.get(activityUrl, { ...svgRequestHeaders });
};
