import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { LoginContext } from "./contexts/LoginContext";

// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(LoginContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
