import React, { createContext, useState } from "react";
import {
  ZoomContextProps,
  ZoomValues,
} from "src/interface/contexts/contexts.interface";

export const ZoomContext = createContext({} as any);

const ZoomContextProvider = (props: ZoomContextProps) => {
  const [zoomValues, setZoomValues] = useState<ZoomValues>({
    zoomSliderValue: 0,
    zoomFactor: 0,
  });

  return (
    <ZoomContext.Provider value={{ zoomValues, setZoomValues }}>
      {props.children}
    </ZoomContext.Provider>
  );
};

export default ZoomContextProvider;
