import React, { useState, useEffect, useContext, FormEvent } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import Auth from "@aws-amplify/auth";
import config from "../../data/config.json";
import sixcoLogo from "../../assets/logo_sixco_white.png";
import sablonoLogo from "../../assets/logo_sablono_delivered_white.png";
import styles from "./Login.module.css";

const Login: React.FC<RouteComponentProps> = (props) => {
  const parsedURLParam = new URLSearchParams(props.location.search).get(
    "redirect"
  );

  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);
  const [loginError, setLoginError] = useState("");
  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    Auth.configure({
      authenticationFlowType: "USER_PASSWORD_AUTH",
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      storage: sessionStorage,
    });

    const checkUserSession = async () => {
      try {
        const validSession = await Auth.currentSession();
        if (
          validSession &&
          sessionStorage.getItem("accessToken") &&
          sessionStorage.getItem("idToken")
        ) {
          setIsLoggedIn(true);
        }
      } catch (err) {
        return;
      }
    };

    checkUserSession();
  }, [setIsLoggedIn]);

  const login = (e: FormEvent) => {
    e.preventDefault();

    Auth.signIn(loginCredentials.username, loginCredentials.password)
      .then((res) => {
        res.getSession((err: any, session: any) => {
          const accessToken = session.getAccessToken().getJwtToken();
          const idToken = session.getIdToken().getJwtToken();
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("idToken", idToken);

          setIsLoggedIn(true);
        });
      })
      .catch((err) => {
        setLoginError(err);
      });
  };

  const clearError = () => {
    setLoginError("");
  };

  if (isLoggedIn) {
    if (!parsedURLParam) return <Redirect to="/reports/select-report" />;
    return <Redirect to={parsedURLParam} />;
  }

  return (
    <div className={styles["login"]}>
      <div className={styles["login__bg"]}></div>
      <div className={styles["login__form-container"]}>
        <span className={styles["login__form-container__title"]}>
          DUBAI UPTOWN TOWER
        </span>
        <span className={styles["login__form-container__subtitle"]}>
          PROJECT REPORTS
        </span>
        {/*  */}
        <form
          onSubmit={login}
          className={styles["login__form-container__form"]}
        >
          <label className={styles["login__form-container__form__label"]}>
            Username
          </label>
          <input
            className={styles["login__form-container__form__input"]}
            onFocus={() => clearError()}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                username: e.target.value,
              })
            }
          />
          <label className={styles["login__form-container__form__label"]}>
            Password
          </label>
          <input
            className={styles["login__form-container__form__input"]}
            onFocus={() => clearError()}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                password: e.target.value,
              })
            }
            type={"password"}
          />
          <button className={styles["login__form-container__form__button"]}>
            LOGIN
          </button>
          {loginError && (
            <span className={styles["login__form-container__form__error"]}>
              Something went wrong when logging in. Provide a valid username and
              password and try again.
            </span>
          )}
        </form>
        {/*  */}
        <div className={styles["login__form-container__footer"]}>
          <div className={styles["login__form-container__footer__sixco-logo"]}>
            <img src={sixcoLogo} alt="Sixco logo" />
          </div>
          <div
            className={styles["login__form-container__footer__sablono-logo"]}
          >
            <img src={sablonoLogo} alt="Sablono logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
