import React, { useContext } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import Auth from "@aws-amplify/auth";
import BackButton from "mdi-react/ChevronLeftIcon";
import sixcoLogoWhite from "../../assets/logo_sixco_white.png";
import sablonoLogoWhite from "../../assets/logo_sablono_delivered_white.png";
import besixLogoColor from "../../assets/besix.png";
import sablonoLogoColor from "../../assets/sablono.svg";
import styles from "./Header.module.css";

const Header: React.FC = () => {
  const selectReportPath = "/reports/select-report";
  let currentRoute = useLocation().pathname;

  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

  const logOut = async () => {
    try {
      await Auth.signOut();
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("idToken");
      setIsLoggedIn(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <div className={styles["header"]}>
          {currentRoute !== selectReportPath && (
            <div className={styles["header__back"]}>
              <Link to={selectReportPath}>
                <BackButton
                  className={styles["header__back--button"]}
                  size={52}
                />
              </Link>
            </div>
          )}
          <div className={styles["header__sixco-white"]}>
            <img src={sixcoLogoWhite} alt="Sixco" />
          </div>
          <div className={styles["header__besix-color"]}>
            <img src={besixLogoColor} alt="Besix" />
          </div>
          <span className={styles["header__title"]}>Dubai Uptown Tower</span>
          <div className={styles["header__sablono-white"]}>
            <img src={sablonoLogoWhite} alt="Sablono" />
          </div>
          <div className={styles["header__sablono-color"]}>
            <img src={sablonoLogoColor} alt="Sablono" />
          </div>
          {
            <span className={styles["header__logout"]} onClick={logOut}>
              Logout
            </span>
          }
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Header;
