import { reports } from "src/data/reports";
import {
  ActivitySvgSource,
  GroupedActivitiesSvgSource,
  GroupedActivitiesSvgData,
  SvgData,
  Svgs,
} from "src/interface/views/report/report.interface";
import { Activities } from "src/data/data.interface";
import { getReportActivitySvg } from "src/data/api/reports";

export class ReportDataFactory {

  /**
   * Go through the groups one by one and fetch all SVGs one by one.
   *
   * @param groupedActivitiesSvgSource
   * @private
   */
  private static async getGroupedActivitiesSvgs(
    groupedActivitiesSvgSource: GroupedActivitiesSvgSource[]
  ): Promise<GroupedActivitiesSvgData[]> {

    // go through the groups one by one and fetch all SVGs one by one
    //
    return groupedActivitiesSvgSource.reduce((waterfall: Promise<GroupedActivitiesSvgData[]>, svgSource: GroupedActivitiesSvgSource) => {
      return waterfall.then((groupedData: GroupedActivitiesSvgData[]) => {
        return ReportDataFactory.getActivitiesSvgs(svgSource.activities).then(
          (activities: SvgData[]) => {
            groupedData.push({
              index: svgSource.index,
              activities,
            });
            return groupedData;
          }
        );

      });
    }, Promise.resolve([]));
  }

  private static getActivitiesSvgs(
    activitiesSvgSource: ActivitySvgSource[]
  ): Promise<SvgData[]> {
    return activitiesSvgSource
      .reduce((waterfall: Promise<SvgData[]>, svgSource: ActivitySvgSource) => {
        return waterfall.then((svgData) =>
          getReportActivitySvg(svgSource.activitySvgUrl).then((res) => {
            svgData.push({
              activityTitle: svgSource.activityTitle,
              groupTitle: svgSource.groupTitle,
              svg: res.data,
            });
            return svgData;
          })
        );
      }, Promise.resolve([]))
      .catch((err) => {
        throw err;
      });
  }

  public static async prepareReportById(reportId: string) {
    const selectedReport = reports.find((report) => report.id === reportId)!;
    const selectedReportDetails = {
      layout: selectedReport.layout,
      title: selectedReport.title,
      subtitle: selectedReport.subtitle,
      activitiesFontSizeAndUnit: selectedReport.activitiesFontSizeAndUnit,
      groupedActivitiesFontSizeAndUnit:
        selectedReport.groupedActivitiesFontSizeAndUnit,
    };

    const groupedActivitiesSvgSource: GroupedActivitiesSvgSource[] = selectedReport.activities
      .map(
        (activity: Activities, index: number) =>
          Array.isArray(activity) && {
            index,
            activities: activity.map((activity) => ({
              activitySvgUrl: `/api/v1/api/reporting/projects/${selectedReport.projectId}/visualizations/${activity.svg}?by_activity_template_id=${activity.id}`,
              activityTitle: activity.title,
              groupTitle: activity.groupTitle,
            })),
          }
      )
      .filter((activity) => activity !== false) as GroupedActivitiesSvgSource[];

    const activitiesSvgSource: ActivitySvgSource[] = selectedReport.activities
      .map(
        (activity: Activities) =>
          !Array.isArray(activity) && {
            activitySvgUrl: `/api/v1/api/reporting/projects/${selectedReport.projectId}/visualizations/${activity.svg}?by_activity_template_id=${activity.id}`,
            activityTitle: activity.title,
            groupTitle: activity.groupTitle,
          }
      )
      .filter((activity) => activity !== false) as ActivitySvgSource[];

    const groupedActivitiesSvgs = await this.getGroupedActivitiesSvgs(
      groupedActivitiesSvgSource
    );

    const activitiesSvgs = await this.getActivitiesSvgs(activitiesSvgSource);

    const svgs: Svgs[] = [...activitiesSvgs];

    if (groupedActivitiesSvgs) {
      groupedActivitiesSvgs.forEach((activitySvg) => {
        svgs.splice(activitySvg.index, 0, activitySvg.activities);
      });
    }

    return {
      ...selectedReportDetails,
      svgs,
    };
  }
}
