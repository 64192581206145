import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ZoomContextProvider from "src/interface/contexts/ZoomContext";
import { ReportDataFactory } from "src/interface/views/report/report.service";
import {
  IRouteParams,
  ReportData,
} from "src/interface/views/report/report.interface";
import { ErrorDisplay } from "src/interface/components/error/error.interface";
import SVGDisplay from "src/interface/views/SVGDisplay";
import LoadingIndicator from "src/interface/components/loading-indicator/LoadingIndicator";
import Error from "src/interface/components/error/Error";

const Report: React.FC = () => {
  const { reportId } = useParams<IRouteParams>();
  const [reportData, setReportData] = useState<ReportData>();
  const [error, setError] = useState<ErrorDisplay>();

  useEffect(() => {
    if (reportData || error) return;
    ReportDataFactory.prepareReportById(reportId)
      .then(setReportData)
      .catch((error) => {
        setError({
          message: error.message,
          status: error.response.status,
        });
      });
  }, [error, reportData, reportId]);

  if (error) {
    return <Error {...error} />;
  } else {
    return (
      <>
        {reportData ? (
          <ZoomContextProvider>
            <SVGDisplay {...reportData} />
          </ZoomContextProvider>
        ) : (
          <LoadingIndicator />
        )}
      </>
    );
  }
};

export default Report;
