import React from "react";
import { GroupSvgWrapperProps } from "src/interface/components/svg-wrapper/svg-wrapper.interface";
import { SvgData } from "src/interface/views/report/report.interface";
import SingleSvgWrapper from "src/interface/components/svg-wrapper/SingleSvgWrapper";
import styles from "src/interface/components/svg-wrapper/SvgWrapper.module.css";

const GroupSvgWrapper: React.FC<GroupSvgWrapperProps> = (props) => {
  const {
    svgsPercentageOfRow,
    svgGroupTitleHeight,
    groupActivitiesFontSizeAndUnit,
    groupTitle,
    svgs,
    layoutColumnsAmount,
  } = props;

  return (
    <div
      className={styles["svg-display__svg-group"]}
      style={
        svgsPercentageOfRow
          ? { flex: `${svgsPercentageOfRow}%` }
          : { flex: `calc(100% / ${layoutColumnsAmount})` }
      }
    >
      <div
        className={styles["svg-display__svg-group__title"]}
        style={{
          height: `${svgGroupTitleHeight}vh`,
          fontSize: groupActivitiesFontSizeAndUnit,
        }}
      >
        {groupTitle}
      </div>
      {Array.isArray(svgs) ? (
        <div className={styles["svg-display__svg-group__svgs"]}>
          {svgs.map((svg: SvgData, index: number) => (
            <SingleSvgWrapper
              key={index}
              titleHeight={svgGroupTitleHeight}
              activitiesFontSizeAndUnit={groupActivitiesFontSizeAndUnit}
              svgs={svg}
              layoutColumnsAmount={layoutColumnsAmount}
            />
          ))}
        </div>
      ) : (
        <SingleSvgWrapper
          titleHeight={svgGroupTitleHeight}
          activitiesFontSizeAndUnit={groupActivitiesFontSizeAndUnit}
          svgs={svgs}
        />
      )}
    </div>
  );
};

export default GroupSvgWrapper;
