import React, { useState, useEffect, useCallback, useContext } from "react";
import { ZoomContext } from "src/interface/contexts/ZoomContext";
import { zoomConstants } from "src/interface/components/zoom-slider/zoom-slider.service";
import ZoomSlider from "src/interface/components/zoom-slider/ZoomSlider";
import { parseSVGWidthHeight } from "./SVGDisplay.service";
import Legend from "src/interface/components/legend/Legend";
import GroupSvgWrapper from "src/interface/components/svg-wrapper/GroupSvgWrapper";
import SingleSvgWrapper from "src/interface/components/svg-wrapper/SingleSvgWrapper";
import { Svgs, SvgData } from "src/interface/views/report/report.interface";
import styles from "./SVGDisplay.module.css";

interface SVGDisplayProps {
  svgs: Svgs[];
  layout: string;
  title: string;
  subtitle: string;
  activitiesFontSizeAndUnit: string;
  groupedActivitiesFontSizeAndUnit?: string;
}

interface StartingValues {
  layoutColumnsAmount: number;
  layoutRowsAmount: number;
  viewportPercentage: number;
  baseRatioHeight: number;
  baseRatioMaxHeight: number;
  baseRatioWidth: number;
  baseRatioMaxWidth: number;
  availableBaseRatios: boolean;
}

const pickFirstUngroupedSvg = (svgs: Svgs[]): string => {
  return (svgs.filter((svg) => !Array.isArray(svg) && svg)[0] as SvgData).svg;
};

const SVGDisplay: React.FC<SVGDisplayProps> = (props) => {
  const { zoomValues, setZoomValues } = useContext(ZoomContext);
  const [error, setError] = useState<string>("");
  const [startingValues, setStartingValues] = useState<StartingValues>({
    layoutColumnsAmount: 0,
    layoutRowsAmount: 0,
    viewportPercentage: 0,
    baseRatioHeight: 0,
    baseRatioMaxHeight: 0,
    baseRatioWidth: 0,
    baseRatioMaxWidth: 0,
    availableBaseRatios: false,
  });

  onbeforeprint = () => {
    setZoomValues({
      zoomSliderValue: zoomConstants.MIN_ZOOM,
      zoomFactor: zoomConstants.MIN_ZOOM,
    });
  };

  useEffect(() => {
    if (
      startingValues.layoutColumnsAmount > 0 ||
      startingValues.layoutRowsAmount > 0
    )
      return;

    const matchLayoutColumns = props.layout.trim().match(/^\d+/);
    const matchLayoutRows = props.layout.trim().match(/\d+$/);

    if (!matchLayoutColumns || !matchLayoutRows) {
      const layoutError = "Invalid reports layout";
      setError(layoutError);
      throw new Error(layoutError);
    }

    setStartingValues({
      ...startingValues,
      layoutColumnsAmount: Number(matchLayoutColumns[0]),
      layoutRowsAmount: Number(matchLayoutRows[0]),
    });
  }, [props.layout, startingValues]);

  const LEGEND_HEIGHT_VIEWPORT_VALUE = 5;
  const SVG_TITLE_HEIGHT_VIEWPORT_VALUE = 3;

  /*
   * References the outer most container to calculate how many percentages
   * it takes up of the viewport. This value minus the percentages for the
   * legend and for the SVG titles together with the margin value are used
   * to define which size the SVGs should be contained in on page resize.
   */
  const containerRef = useCallback(
    (node) => {
      if (startingValues.viewportPercentage > 0 || node === null) return;

      // The margin is used to provide some top and bottom breathing room.
      const MARGIN = 5;

      const containerHeight = node.clientHeight;
      const viewportHeight = window.innerHeight;
      const containerPercentageOfViewport =
        (containerHeight / viewportHeight) * 100;
      const viewportPercentage =
        containerPercentageOfViewport -
        LEGEND_HEIGHT_VIEWPORT_VALUE -
        SVG_TITLE_HEIGHT_VIEWPORT_VALUE -
        MARGIN;

      setStartingValues({
        ...startingValues,
        viewportPercentage,
      });
    },
    [startingValues]
  );

  useEffect(() => {
    if (startingValues.availableBaseRatios) return;

    /*
     * Parses the dimensions of one SVG and multiplies its width and height
     * with the layout columns and rows amount. These values are later used
     * to derive the width/height and height/width base ratios. The correct
     * aspect ratio for the container holding all SVGs can be defined using
     * just one SVGs dimensions.
     */
    const parsedSVGWidthHeight = parseSVGWidthHeight(
      pickFirstUngroupedSvg(props.svgs)
    );
    const svgWidth = parsedSVGWidthHeight[0];
    const svgHeight = parsedSVGWidthHeight[1];

    if (
      svgWidth === 0 ||
      svgHeight === 0 ||
      startingValues.viewportPercentage === 0 ||
      startingValues.layoutColumnsAmount === 0 ||
      startingValues.layoutRowsAmount === 0
    )
      return;

    const svgContainerWidthHeightRatio =
      (svgWidth * startingValues.layoutColumnsAmount) /
      (svgHeight * startingValues.layoutRowsAmount);
    const svgContainerHeightWidthRatio =
      (svgHeight * startingValues.layoutRowsAmount) /
      (svgWidth * startingValues.layoutColumnsAmount);

    /*
     * Calculates the base values for fitting all SVGs within the viewport,
     * regardless of browser resize and while always maintaining the aspect
     * ratio. The values are later passed to the CSS properties which makes
     * sure the aspect ratio always is kept based on the following formula:
     *
     *
     * height: height of container div / width of container div * X (X = desired viewport value, i.e. 100)
     * max-height: Xvh (X = desired viewport value, i.e. 100)
     * width: Xvw (X = desired viewport value, i.e. 100)
     * max-width: width of container div / height of container div * X Xvw (X = desired viewport value, i.e. 100)
     */
    const baseRatioHeight =
      svgContainerHeightWidthRatio * startingValues.viewportPercentage;
    const baseRatioMaxHeight = startingValues.viewportPercentage;
    const baseRatioWidth = startingValues.viewportPercentage;
    const baseRatioMaxWidth =
      svgContainerWidthHeightRatio * startingValues.viewportPercentage;

    setStartingValues({
      ...startingValues,
      baseRatioHeight,
      baseRatioMaxHeight,
      baseRatioWidth,
      baseRatioMaxWidth,
      availableBaseRatios: true,
    });
  }, [props, props.svgs, startingValues]);

  const svgContainerStyles = {
    height: `${startingValues.baseRatioHeight}vw`,
    maxHeight: `${startingValues.baseRatioMaxHeight}vh`,
    width: `calc(${startingValues.baseRatioWidth}vw + ${zoomValues.zoomFactor}px)`,
    maxWidth: `calc(${startingValues.baseRatioMaxWidth}vh + ${zoomValues.zoomFactor}px)`,
  };

  const numberOfGroupedSvgs: number = props.svgs
    .map((groupedSvgs) => Array.isArray(groupedSvgs) && groupedSvgs)
    .reduce((svgsAmount: number, groupedSvgs) => {
      if (groupedSvgs) {
        svgsAmount += groupedSvgs.length;
      }

      return svgsAmount;
    }, 0);

  const numberOfSimpleSvgs: number = props.svgs.filter(
    (svg) => !Array.isArray(svg) && svg
  ).length;

  const totalNumberOfSvgs: number = numberOfGroupedSvgs + numberOfSimpleSvgs;
  const getSvgsPerRow = (): number => {
    const svgsPerRow = totalNumberOfSvgs / startingValues.layoutRowsAmount;
    return Number.isNaN(svgsPerRow) ? 0 : svgsPerRow;
  };

  const legendStyles = {
    flex: "100%",
    height: `${LEGEND_HEIGHT_VIEWPORT_VALUE}vh`,
  };

  /*
   * Sets css class used for print only. It defines whether the print
   * styles should be adjusted for landscape or portrait SVGs prints.
   */
  const svgPrintStyles =
    startingValues.baseRatioHeight > startingValues.baseRatioWidth
      ? "portrait"
      : "landscape";

  const svgTitleHeight: number = SVG_TITLE_HEIGHT_VIEWPORT_VALUE;
  const svgGroupTitleHeight: number = SVG_TITLE_HEIGHT_VIEWPORT_VALUE / 2;

  if (error) return <h3>{error}</h3>;

  return (
    <div className={styles["svg-display"]} ref={containerRef}>
      {startingValues.availableBaseRatios && (
        <>
          <ZoomSlider />
          <div
            className={`
          ${styles["svg-display__svg-container"]}
          ${styles[svgPrintStyles]}
        `}
            style={svgContainerStyles}
          >
            <div
              className={styles["svg-display__svg-container__legend"]}
              style={legendStyles}
            >
              <Legend title={props.title} subtitle={props.subtitle} />
            </div>
            {props.svgs.map((svg, index) => {
              if (Array.isArray(svg)) {
                const groupedSvgs: SvgData[] = svg;
                const svgsPercentageOfRow =
                  (groupedSvgs.length * 100) / getSvgsPerRow();

                return (
                  <GroupSvgWrapper
                    key={index}
                    svgsPercentageOfRow={svgsPercentageOfRow}
                    svgGroupTitleHeight={svgGroupTitleHeight}
                    groupActivitiesFontSizeAndUnit={
                      props.groupedActivitiesFontSizeAndUnit!
                    }
                    groupTitle={groupedSvgs[0].groupTitle!}
                    svgs={groupedSvgs}
                    layoutColumnsAmount={startingValues.layoutColumnsAmount}
                  />
                );
              } else {
                if (svg.groupTitle) {
                  return (
                    <GroupSvgWrapper
                      key={index}
                      svgGroupTitleHeight={svgGroupTitleHeight}
                      groupActivitiesFontSizeAndUnit={
                        props.groupedActivitiesFontSizeAndUnit!
                      }
                      groupTitle={svg.groupTitle}
                      svgs={svg}
                      layoutColumnsAmount={startingValues.layoutColumnsAmount}
                    />
                  );
                } else {
                  return (
                    <SingleSvgWrapper
                      key={index}
                      titleHeight={svgTitleHeight}
                      activitiesFontSizeAndUnit={
                        props.activitiesFontSizeAndUnit
                      }
                      svgs={svg}
                      layoutColumnsAmount={startingValues.layoutColumnsAmount}
                    />
                  );
                }
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default SVGDisplay;
