import React from "react";
import { SingleSvgWrapperProps } from "src/interface/components/svg-wrapper/svg-wrapper.interface";
import styles from "src/interface/components/svg-wrapper/SvgWrapper.module.css";

const SingleSvgWrapper: React.FC<SingleSvgWrapperProps> = (props) => {
  const {
    titleHeight,
    activitiesFontSizeAndUnit,
    svgs,
    layoutColumnsAmount,
  } = props;

  return (
    <div
      style={
        layoutColumnsAmount
          ? { flex: `calc(100% / ${layoutColumnsAmount})` }
          : {}
      }
    >
      <div className={styles["svg-display__svg-container__content"]}>
        <div
          className={styles["svg-display__svg-container__content__title"]}
          style={{
            height: `${titleHeight}vh`,
            fontSize: activitiesFontSizeAndUnit,
          }}
        >
          {svgs.activityTitle}
        </div>
        <div className={styles["svg-display__svg-container__content__svg"]}>
          <img
            src={`data:image/svg+xml;base64,${window.btoa(svgs.svg)}`}
            alt={svgs.activityTitle}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleSvgWrapper;
