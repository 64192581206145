import React from "react";
import { IReportTileData } from "src/interface/components/report-tile/report-tile.interface";
import ImageBroken from "mdi-react/ImageBrokenVariantIcon";
import styles from "src/interface/components/report-tile/ReportTile.module.css";

const ReportTile: React.FC<IReportTileData> = (props) => {
  return (
    <div className={styles["report-tile"]}>
      <div className={styles["report-tile__image"]}>
        {props.thumb ? (
          <img src={URL.createObjectURL(props.thumb)} alt="Report thumbnail" />
        ) : (
          <ImageBroken
            className={styles["report-tile__image__error"]}
            size={90}
          />
        )}
      </div>
      <span className={styles["report-tile__title"]}>{props.title}</span>
      <span className={styles["report-tile__subtitle"]}>{props.subtitle}</span>
    </div>
  );
};

export default ReportTile;
