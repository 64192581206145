import { getReportsThumbs } from "src/data/api/reports";
import { Report } from "src/data/data.interface";
import { IReportTileData } from "src/interface/components/report-tile/report-tile.interface";

export class ReportTilesDataFactory {
  private static formatForSorting(text: string): string {
    return text.trim().toLowerCase();
  }

  private static isAuthError(errorStatusCode: number): boolean {
    return errorStatusCode === 403;
  }

  public static async prepareReportTileData(
    reports: Report[]
  ): Promise<IReportTileData[]> {
    return await Promise.all(
      reports.map(async (report) => {
        const reportDetails = {
          id: report.id,
          title: report.title,
          subtitle: report.subtitle,
        };

        try {
          const reportThumb = await getReportsThumbs(report.previewUrl);

          return {
            ...reportDetails,
            thumb: new Blob([reportThumb.data]),
          };
        } catch (error) {
          if (this.isAuthError(error.response.status)) {
            throw error;
          } else {
            return {
              ...reportDetails,
              error: error.message,
            };
          }
        }
      })
    );
  }

  public static sortReports(reports: IReportTileData[]): IReportTileData[] {
    return reports.sort((a: IReportTileData, b: IReportTileData) => {
      const reportTitleA = this.formatForSorting(a.title);
      const reportTitleB = this.formatForSorting(b.title);
      const titleSort = reportTitleA.localeCompare(reportTitleB);
      if (titleSort === 0) {
        const reportSubtitleA = this.formatForSorting(a.subtitle);
        const reportSubtitleB = this.formatForSorting(b.subtitle);
        return reportSubtitleA.localeCompare(reportSubtitleB);
      }
      return titleSort;
    });
  }
}
