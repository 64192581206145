export const parseSVGWidthHeight = (svg: string): number[] => {
  const parser = new DOMParser();

  try {
    const viewBoxString = parser
      .parseFromString(svg, "text/html")
      .querySelector("svg")!
      .getAttribute("viewBox");

    const viewBoxArray = viewBoxString!.split(/\s+|,/);
    const svgWidth = Number(viewBoxArray[2]);
    const svgHeight = Number(viewBoxArray[3]);

    return [svgWidth, svgHeight];
  } catch (err) {
    throw new Error(err);
  }
};
