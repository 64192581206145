import { Report } from "src/data/data.interface";

/*
  UUIDs free to be used (remove here when used !!!)

    
    73ad1194-946e-4794-b9eb-0449a3a8417a
    86810ced-334e-4a0b-9c0f-7d25a5ee7add
    11b56f5e-f8bd-4a08-b490-48b760420033
    cc0b98a8-b30b-4300-a9e2-06f5faaf7af1
    3931f9a3-183b-432f-831f-f2b24018714d
    bf674c3b-5ae2-46fc-b172-233f62c88c53
    3943afaa-56a2-424c-993b-260d8b48ec13
    beaf2254-7168-4db0-a70f-499a93d3114e

  HINT to find an ok starting point for "activitiesFontSizeAndUnit"
    -> a landscape print should start with 0.8vh and decrease if required
    -> a portrait print should start with 0.8vh and increase if possible

 */

export const reports: Report[] = [
  {
    id: "d9c391c0-0ca9-40bf-ae28-dc23d0002cfa",
    layout: "3x2",
    title: "E&P Update",
    subtitle: "Level - 0B2 to Level - 17",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/692301?fit=thumb",
    activities: [
      {
        svg: 5250,
        id: "430be129-335c-4eff-d654-d89f91fbf6d0",
        title: "MEP Builderworks Drawing Status",
      },
      {
        svg: 5250,
        id: "197c47c5-40ff-480e-f9fc-5069f4b587ae",
        title: "Concrete Drawing Status",
      },
      {
        svg: 5250,
        id: "b7b9a28e-cf78-479c-8aa4-ae4a5beb333d",
        title: "Methods Drawing Status",
      },
      {
        svg: 5250,
        id: "70800eac-ca86-4deb-d7ea-f12b86c41e61",
        title: "Reinforcement Drawing Status",
      },
      {
        svg: 5250,
        id: "e2857881-132b-4ed7-bb62-20f73f7a314a",
        title: "Rebar Call Off",
      },
      {
        svg: 5250,
        id: "fc6f2bb3-20b6-4607-80df-289bdef934f3",
        title: "Construction Updates",
      },
    ],
  },
  {
    id: "3be13c82-daba-4f52-bd1a-3c06b7554adb",
    layout: "3x2",
    title: "E&P Update",
    subtitle: "Level - 18 to Level - 38",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/631267?fit=thumb",
    activities: [
      {
        svg: 4581,
        id: "430be129-335c-4eff-d654-d89f91fbf6d0",
        title: "MEP - Builders Works",
      },
      {
        svg: 4581,
        id: "197c47c5-40ff-480e-f9fc-5069f4b587ae",
        title: "Concrete - SHD Production",
      },
      {
        svg: 4581,
        id: "b7b9a28e-cf78-479c-8aa4-ae4a5beb333d",
        title: "Methods - Scaffolding, Formwork",
      },
      {
        svg: 4581,
        id: "70800eac-ca86-4deb-d7ea-f12b86c41e61",
        title: "Rebar - Shop Drawing",
      },
      {
        svg: 4581,
        id: "e2857881-132b-4ed7-bb62-20f73f7a314a",
        title: "Rebar - Call off & Delivery",
      },
      {
        svg: 4581,
        id: "fc6f2bb3-20b6-4607-80df-289bdef934f3",
        title: "Construction Update",
      },
    ],
  },
  {
    id: "ef944629-983c-4c93-b535-8814120dc291",
    layout: "3x2",
    title: "E&P Update",
    subtitle: "Level - 39 to Level - 59",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/692181?fit=thumb",
    activities: [
      {
        svg: 5246,
        id: "430be129-335c-4eff-d654-d89f91fbf6d0",
        title: "MEP Builderworks Drawing Status",
      },
      {
        svg: 5246,
        id: "197c47c5-40ff-480e-f9fc-5069f4b587ae",
        title: "Concrete Drawing Status",
      },
      {
        svg: 5246,
        id: "b7b9a28e-cf78-479c-8aa4-ae4a5beb333d",
        title: "Methods Drawing Status",
      },
      {
        svg: 5246,
        id: "70800eac-ca86-4deb-d7ea-f12b86c41e61",
        title: "Reinforcement Drawing Status",
      },
      {
        svg: 5246,
        id: "e2857881-132b-4ed7-bb62-20f73f7a314a",
        title: "Rebar Call Off",
      },
      {
        svg: 5246,
        id: "fc6f2bb3-20b6-4607-80df-289bdef934f3",
        title: "Construction Updates",
      },
    ],
  },
  {
    id: "f0b94a4f-53fa-455d-9c06-ce816ccf6525",
    layout: "3x2",
    title: "E&P Update",
    subtitle: "Level - 60 to Level - 78",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/703910?fit=thumb",
    activities: [
      {
        svg: 5382,
        id: "430be129-335c-4eff-d654-d89f91fbf6d0",
        title: "MEP - Builders Works",
      },
      {
        svg: 5382,
        id: "197c47c5-40ff-480e-f9fc-5069f4b587ae",
        title: "Concrete - SHD Production",
      },
      {
        svg: 5382,
        id: "b7b9a28e-cf78-479c-8aa4-ae4a5beb333d",
        title: "Methods - Scaffolding, Formwork",
      },
      {
        svg: 5382,
        id: "70800eac-ca86-4deb-d7ea-f12b86c41e61",
        title: "Rebar - Shop Drawing",
      },
      {
        svg: 5382,
        id: "e2857881-132b-4ed7-bb62-20f73f7a314a",
        title: "Rebar - Call off & Delivery",
      },
      {
        svg: 5382,
        id: "fc6f2bb3-20b6-4607-80df-289bdef934f3",
        title: "Construction Update",
      },
    ],
  },
  {
    id: "a2d63951-231c-41a7-b98b-15a250d25162",
    layout: "3x2",
    title: "STR - Site Update",
    subtitle: "STR site Tower striking B2 to L17",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/692316?fit=thumb",
    activities: [
      {
        svg: 5251,
        id: "a53e7b72-9a06-4b43-d844-88c666a10e06",
        title: "Casting",
      },
      {
        svg: 5251,
        id: "67552009-0b1f-4ffc-bc51-b0cae387bec6",
        title: "PT Stressing",
      },
      {
        svg: 5251,
        id: "62db7455-6824-4a2a-b38c-a95501536dce",
        title: "Permit to strike",
      },
      {
        svg: 5251,
        id: "1344763c-746a-4ac7-e184-c82ff1aa810f",
        title: "Striking + backpropping",
      },
      {
        svg: 5251,
        id: "f2ce96be-9f83-403b-a9a6-fbb08c8318e2",
        title: "Permit to remove backpropping",
      },
      {
        svg: 5251,
        id: "5338f972-7e8a-44cc-eacd-a8f0629e8b9e",
        title: "Remove backpropping",
      },
    ],
  },
  {
    id: "040583ac-381f-4eac-a272-9da2f5f3e5e9",
    layout: "3x2",
    title: "STR - E&P Update",
    subtitle: "Podium B02 - L07",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/630906?fit=thumb",
    activities: [
      {
        svg: 4578,
        id: "430be129-335c-4eff-d654-d89f91fbf6d0",
        title: "MEP BuilderWorks Drawing Status",
      },
      {
        svg: 4578,
        id: "197c47c5-40ff-480e-f9fc-5069f4b587ae",
        title: "Concrete Drawing Status",
      },
      {
        svg: 4578,
        id: "b7b9a28e-cf78-479c-8aa4-ae4a5beb333d",
        title: "Methods Drawing Status",
      },
      {
        svg: 4578,
        id: "70800eac-ca86-4deb-d7ea-f12b86c41e61",
        title: "Reinforcement Drawing Status",
      },
      {
        svg: 4578,
        id: "e2857881-132b-4ed7-bb62-20f73f7a314a",
        title: "Rebar Call off",
      },
      {
        svg: 4578,
        id: "fc6f2bb3-20b6-4607-80df-289bdef934f3",
        title: "Construction Update",
      },
    ],
  },
  {
    id: "e770f875-13f3-4239-b002-b5f1111e85af",
    layout: "3x2",
    title: "STR -Site Update",
    subtitle: "Podium Striking B02 - L07",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/631307?fit=thumb",
    activities: [
      {
        svg: 4589,
        id: "a53e7b72-9a06-4b43-d844-88c666a10e06",
        title: "Casting",
      },
      {
        svg: 4589,
        id: "67552009-0b1f-4ffc-bc51-b0cae387bec6",
        title: "PT Stressing",
      },
      {
        svg: 4589,
        id: "62db7455-6824-4a2a-b38c-a95501536dce",
        title: "Permit to Strike",
      },
      {
        svg: 4589,
        id: "1344763c-746a-4ac7-e184-c82ff1aa810f",
        title: "Striking + backpropping",
      },
      {
        svg: 4589,
        id: "f2ce96be-9f83-403b-a9a6-fbb08c8318e2",
        title: "Permit to remove backpropping",
      },
      {
        svg: 4589,
        id: "5338f972-7e8a-44cc-eacd-a8f0629e8b9e",
        title: "Remove backpropping",
      },
    ],
  },
  {
    id: "8b85958f-65ab-4c6d-881b-37ab76c132e4",
    layout: "3x2",
    title: "Engineering & Construction Update",
    subtitle: "Facade All Elevations",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/769799?fit=thumb",
    activitiesFontSizeAndUnit: "1vh", // the blue title box is 3vh
    activities: [
      {
        svg: 9952,
        id: "9686addd-697e-46d2-c771-e81177452888",
        title: "Structure hand-over",
      },
      {
        svg: 9952,
        id: "709a7312-4498-426d-ca7a-3d75a532521e",
        title: "Bracket installation",
      },
      {
        svg: 9952,
        id: "51295a17-fe2e-413f-ade1-3f74a0c78623",
        title: "Fabrication drawing",
      },
      {
        svg: 9952,
        id: "0b588a5d-0292-4bae-dfb0-162327c7de3c",
        title: "Assembly/fabrication",
      },
      {
        svg: 9952,
        id: "2bc412b8-b5d4-4144-a2e9-3b9d9df6987f",
        title: "Delivery panels",
      },
      {
        svg: 9952,
        id: "48259b1a-738f-4875-88ae-d1a9b63b59b8",
        title: "Panels installation",
      },
    ],
  },
  {
    id: "dc2327af-59ea-445c-97ad-a9a5f0719a0c",
    layout: "10x1",
    title: "Engineering & Construction Update",
    subtitle: "BIM Coordination shop drawings",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.9vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/681691?fit=thumb",
    activities: [
      {
        svg: 5122,
        id: "db027f34-c436-4443-ce47-5431b1cdeb61",
        title: "ARC IFC Received",
      },
      {
        svg: 5122,
        id: "746da446-8375-4895-cbfd-771af8b9cc2c",
        title: "ARC Wall Finish",
      },
      {
        svg: 5122,
        id: "2b564920-1a58-4bec-ebe3-d2e18dc168d3",
        title: "ARC Flooring & Ceiling Tiles",
      },
      {
        svg: 5122,
        id: "b79cdc4b-595c-4ac5-e94c-73ade2792005",
        title: "MEP - Mechanical",
      },
      {
        svg: 5122,
        id: "1d43130d-16f2-45f8-92d8-00c05f57502e",
        title: "MEP - Electrical",
      },
      {
        svg: 5122,
        id: "55b3ffe1-faca-4c8e-99dc-ec6bdaa1b8ae",
        title: "MEP - RCP",
      },
      {
        svg: 5122,
        id: "d5d708cd-73f2-40fa-bf03-37230af45873",
        title: "MEP - Blockwall Builders works",
      },
      {
        svg: 5122,
        id: "03ed77b1-7649-4453-9687-f4b9da68a666",
        title: "ARC - BBR Opening",
      },
      {
        svg: 5122,
        id: "f25a9806-4090-44a9-fdea-0c7c7a3c80f9",
        title: "MEP -Coordination",
      },
      {
        svg: 5122,
        id: "db358bb5-5536-4432-8ed6-cfa83d50e363",
        title: "ARC - Consolidation ARC-MEP",
      },
    ],
  },
  {
    id: "e8ad8d3a-6eee-4360-a009-b4d7b109d40e",
    layout: "18x1",
    title: "Engineering & Construction Update",
    subtitle: "BOH Engineering",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.85vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/681737?fit=thumb",
    activities: [
      {
        svg: 5383,
        id: "d5d708cd-73f2-40fa-bf03-37230af45873",
        title: "Blockwall Builders work",
      },
      {
        svg: 5383,
        id: "2b564920-1a58-4bec-ebe3-d2e18dc168d3",
        title: "Flooring & Ceiling tiles",
      },
      {
        svg: 5383,
        id: "55b3ffe1-faca-4c8e-99dc-ec6bdaa1b8ae",
        title: "RCP",
      },
      {
        svg: 5383,
        id: "758a28ae-6bc1-409b-856c-73b108df074e",
        title: "Sanitary works",
      },
      {
        svg: 5383,
        id: "b79cdc4b-595c-4ac5-e94c-73ade2792005",
        title: "Mechanical",
      },
      {
        svg: 5383,
        id: "1d43130d-16f2-45f8-92d8-00c05f57502e",
        title: "Electrical",
      },
      {
        svg: 5124,
        id: "5e274ec5-0b00-4659-9dd3-0155198d8b71",
        title: "General",
      },
      {
        svg: 5124,
        id: "aeccc2b0-b833-48b9-944c-12740aa3bd5a",
        title: "Details",
      },
      {
        svg: 5124,
        id: "144590a1-eba4-434d-f6cd-aa94d12b673b",
        title: "Blockwork",
      },
      {
        svg: 5124,
        id: "bd1386f0-befb-4fc0-b3de-70527f3eef0f",
        title: "Flooring",
      },
      {
        svg: 5124,
        id: "7c375590-7b7a-4b6a-fdc5-382af7e8292c",
        title: "Drywall",
      },
      {
        svg: 5124,
        id: "38ff7d32-b733-42b1-87af-de451f4df950",
        title: "Screed + Waterproofing",
      },
      {
        svg: 5124,
        id: "fcf81394-2184-4849-8fb1-15673f0cb561",
        title: "Finishing Schedule",
      },
      {
        svg: 5124,
        id: "4b210b00-371a-4abd-ffe9-c4c984bca46b",
        title: "Soffit Insulation",
      },
      {
        svg: 5124,
        id: "cd6423a8-e0bb-49b3-8322-256a12c8b02f",
        title: "Ceiling",
      },
      {
        svg: 5124,
        id: "4820fcf3-42e1-4b93-d727-ff241d9a9e5e",
        title: "Small Power Plan",
      },
      {
        svg: 5124,
        id: "765ef54f-03c6-4ddb-8ced-7b438aff970f",
        title: "Door Schedule",
      },
      {
        svg: 5124,
        id: "9541a49b-05a1-4bbd-c7dc-bd270cfe92e9",
        title: "Blow up plans",
      },
    ],
  },
  {
    id: "b5f10bf1-022a-42cc-beee-0ce16cc43219",
    layout: "22x1",
    title: "Engineering & Construction Update",
    subtitle: "Architecture & MEP B2",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.7vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/682033?fit=thumb",
    activities: [
      {
        svg: 5132,
        id: "9186bfbd-18a6-49cf-bc85-0e4737c35f0b",
        title: "Wall - Setting out",
      },
      {
        svg: 5132,
        id: "264061e4-6425-47cd-9c8a-dd2c5c50753c",
        title: "Wall - Blockwork",
      },
      {
        svg: 5132,
        id: "61a222bb-8244-416f-da37-6284771ba692",
        title: "Wall - MEP marking",
      },
      {
        svg: 5132,
        id: "b9c849b6-5f88-4937-ff9e-daf972bebfbd",
        title: "Wall - MEP  chipping",
      },
      {
        svg: 5132,
        id: "fec2023c-def6-41c8-af32-1d5cbfe7ef60",
        title: "Blockwork rendering",
      },
      {
        svg: 5132,
        id: "9edd21b9-ee49-4be2-9929-c488c42d9fd9",
        title: "Ceiling - Soffit skim coat",
      },
      {
        svg: 5132,
        id: "b9065939-72f4-486b-e8cf-f99e4d2467a7",
        title: "Ceiling - Paint 1st and 2nd coat",
      },
      {
        svg: 5132,
        id: "cf8ab739-fa4a-469a-cb1c-ff986261bcd6",
        title: "Ceiling fs - Soffit Insulation",
      },
      {
        svg: 5132,
        id: "10a68f98-b9a7-4e97-c434-f2ed95fe4c4d",
        title: "Ceiling fs - Close grid",
      },
      {
        svg: 5132,
        id: "55514cec-69c1-4192-a81e-d2496f5f5ccc",
        title: "Embedded / concealed PVC conduits",
      },
      {
        svg: 5132,
        id: "d81a258e-db78-4a2a-e9a7-44d760c8ea5d",
        title: "Cable tray/ trunking/ ladder",
      },
      {
        svg: 5132,
        id: "5d225683-b682-4858-da8d-cdc9f602ca6d",
        title: "Exposed GI conduits",
      },
      {
        svg: 5132,
        id: "0319750e-2f70-46e5-8c43-38318875d910",
        title: "Embedded PLDR pipes for wet area",
      },
      {
        svg: 5132,
        id: "e255bbab-5686-4beb-ebea-b1433056b42e",
        title: "Fire protection piping, valves & fitting",
      },
      {
        svg: 5132,
        id: "4f24541e-3013-4328-88fb-30c279ef4e7d",
        title: "Drainage piping",
      },
      {
        svg: 5132,
        id: "57c91435-9019-403d-cda6-f21ca947b849",
        title: "Water supply pipes",
      },
      {
        svg: 5132,
        id: "f2f4a786-0805-4bb1-fd20-68b29f2fb4b2",
        title: "Condensate drain pipe & insulation",
      },
      {
        svg: 5132,
        id: "eabd3b99-ddc7-4e43-e05b-0482d10640ff",
        title: "Chilled water piping & insulation",
      },
      {
        svg: 5132,
        id: "b0e206fb-1fd6-45de-e219-dc684471cc2c",
        title: "GI ducting & dampers",
      },
      {
        svg: 5132,
        id: "19a58549-6d11-4eea-aa0f-4ecc2d18ebf0",
        title: "Fire rated ducting & dampers",
      },
      {
        svg: 5132,
        id: "92303f10-2562-4f2f-9dcf-f8c1f8a6f8ee",
        title: "Level marking",
      },
      {
        svg: 5132,
        id: "3085862c-dcec-480a-edd6-57e58e5f0e6d",
        title: "Screed",
      },
    ],
  },
  {
    id: "68c7ae35-e33d-4c8b-984a-181759d22c07",
    layout: "4x1",
    title: "Engineering & Construction Update",
    subtitle: "MEP Risers",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "1vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/682148?fit=thumb",
    activities: [
      {
        svg: 5141,
        id: "9d120b52-ed5a-49ae-b155-9172d9c8733d",
        title: "Structural clearance (making good)",
      },
      {
        svg: 5141,
        id: "89ad20c4-fa02-44c0-b7fd-fcdec98a8242",
        title: "MEP installation",
      },
      {
        svg: 5141,
        id: "516d20b4-4b18-492d-8bff-1f9de2bbde51",
        title: "MEP testing + touch up",
      },
      {
        svg: 5141,
        id: "5e0846f4-1efe-4407-f4d6-27eee796da70",
        title: "Riser closure (blockwork)",
      },
    ],
  },
  {
    id: "8061eefa-4cd2-4732-9f94-fd562a8bb127",
    layout: "25x1",
    title: "Engineering & Construction Update",
    subtitle: "MEP Shop Drawings",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.65vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/682720?fit=thumb",
    activities: [
      {
        svg: 5168,
        id: "7245f1e1-38fa-4539-cf04-91343c77dbf0",
        title: "BIM Cooridnation",
      },
      {
        svg: 5168,
        id: "ed098fad-74b8-481d-ff08-9251c7a46534",
        title: "Builders work - Blockwork",
      },
      {
        svg: 5168,
        id: "aa339b6a-ad07-461b-c74d-3639c0e78970",
        title: "HVAC - Ventilation SHD",
      },
      {
        svg: 5168,
        id: "2b6ca99c-2c60-4753-d0fa-736b09cc4765",
        title: "HVAC - Chilled Water SHD",
      },
      {
        svg: 5168,
        id: "27a6d775-ef35-4eda-88e7-6d1e3b7abd37",
        title: "Plumbing - Drainage",
      },
      {
        svg: 5168,
        id: "01c57fa6-25ff-4aa6-b6ef-7b37c7b353ad",
        title: "Plumbing - Water supply",
      },
      {
        svg: 5168,
        id: "7fc05dd7-14a2-4d03-8d47-413d95f65b5b",
        title: "Plumbing - Gas systems",
      },
      {
        svg: 5168,
        id: "ccb0d332-58bf-4a84-b6e1-2ac1befd0b7f",
        title: "Fire fighting - Fire protection",
      },
      {
        svg: 5168,
        id: "937eed17-04d1-4b77-a46c-87c9ae99966e",
        title: "Fire fighting - fire suppression",
      },
      {
        svg: 5168,
        id: "10fb681c-4a2b-4d5a-b19b-0e950945ff7b",
        title: "ELV - CCTV",
      },
      {
        svg: 5168,
        id: "ff104699-c025-4bc7-8cbf-772cb04983ce",
        title: "ELV - Access control",
      },
      {
        svg: 5168,
        id: "a2557316-c115-41fc-cdfb-bb828bc415e9",
        title: "ELV - Fire alarm",
      },
      {
        svg: 5168,
        id: "a40fea74-453e-496b-91dc-121790841d2a",
        title: "ELV - Telecom",
      },
      {
        svg: 5168,
        id: "ef6c1f57-e8b1-408f-c426-bcddbdc2c955",
        title: "ELV - CBS",
      },
      {
        svg: 5168,
        id: "d5298cf2-2c59-4a59-90ae-2d749583e9d7",
        title: "ELV - Fire Alarm",
      },
      {
        svg: 5168,
        id: "0d18a9a3-a467-421c-99b1-26ec0b794111",
        title: "ELV - AV, PA, BGM",
      },
      {
        svg: 5168,
        id: "8c43af14-6054-4ecc-fcc0-91eadfbbc7e9",
        title: "ELV - BMS",
      },
      {
        svg: 5168,
        id: "d247d822-9939-4fa6-eef5-4edadcf24219",
        title: "ELV - Lighting control",
      },
      {
        svg: 5168,
        id: "f2a776f0-b043-449d-efc4-6e3ec6c874ba",
        title: "Electrical - Containment",
      },
      {
        svg: 5168,
        id: "ae5a7379-ec1e-48ea-d7b9-913e322216ff",
        title: "Electrical - Lighting SHD",
      },
      {
        svg: 5168,
        id: "e4940ff2-bd55-4fb0-b203-22fc2e551265",
        title: "Electrical - Small power",
      },
      {
        svg: 5168,
        id: "86f19527-902c-41b3-a4d3-2b5a74ddef51",
        title: "Electrical - Earthing & lighting protection",
      },
      {
        svg: 5168,
        id: "96d941b2-9510-4e97-9ed1-b7b72a81e335",
        title: "Electrical - Aviation obstruction",
      },
      {
        svg: 5168,
        id: "d32f6b11-6f66-4bef-f76b-c8d2cf801298",
        title: "Coordination - MEP coordination",
      },
      {
        svg: 5168,
        id: "93fc8f6a-9e28-4cf0-ccdd-66e21c886e1d",
        title: "Coordination - RCP",
      },
    ],
  },
  {
    id: "0b11b16b-20b4-4dea-ab71-979d55f9da4d",
    layout: "4x1",
    title: "Vertical Transportation",
    subtitle: "Firefighting",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/711127?fit=thumb",
    activities: [
      {
        svg: 5485,
        id: "667a3c09-d4b5-46cb-8a38-c5a8503009fa",
        title: "Shaft Clearance",
      },
      {
        svg: 5485,
        id: "366bbfe8-30be-4901-8ee7-b125ca98c39c",
        title: "Installation of Doors",
      },
      {
        svg: 5485,
        id: "cd223cf8-0bff-47b2-ea1d-91eb6261a81a",
        title: "Control equipment’s",
      },
      {
        svg: 5485,
        id: "6081dd8e-b0a8-447d-d59c-6a5389e6e395",
        title: "Machines on the Technical Room",
      },
    ],
  },
  {
    id: "f3001646-492a-4a9c-a081-bb9e971684a4",
    layout: "2x2",
    title: "Vertical Transportation",
    subtitle: " Office",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "1vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/711107?fit=thumb",
    activities: [
      {
        svg: 5484,
        id: "667a3c09-d4b5-46cb-8a38-c5a8503009fa",
        title: "Shaft Clearance",
      },
      {
        svg: 5484,
        id: "366bbfe8-30be-4901-8ee7-b125ca98c39c",
        title: "Installation of Doors",
      },
      {
        svg: 5484,
        id: "cd223cf8-0bff-47b2-ea1d-91eb6261a81a",
        title: "Control equipment’s",
      },
      {
        svg: 5484,
        id: "6081dd8e-b0a8-447d-d59c-6a5389e6e395",
        title: "Machines on the Technical Room",
      },
    ],
  },
  {
    id: "2733c276-991d-47c8-9d39-95fa2484ceef",
    layout: "4x1",
    title: "Vertical Transportation",
    subtitle: "Sky lobby",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "1vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/711018?fit=thumb",
    activities: [
      {
        svg: 5482,
        id: "667a3c09-d4b5-46cb-8a38-c5a8503009fa",
        title: "Shaft Clearance",
      },
      {
        svg: 5482,
        id: "366bbfe8-30be-4901-8ee7-b125ca98c39c",
        title: "Installation of Doors",
      },
      {
        svg: 5482,
        id: "cd223cf8-0bff-47b2-ea1d-91eb6261a81a",
        title: "Control equipment’s",
      },
      {
        svg: 5482,
        id: "6081dd8e-b0a8-447d-d59c-6a5389e6e395",
        title: "Machines on the Technical Room",
      },
    ],
  },
  {
    id: "9b86f176-d1ae-40b9-9356-64c4d1e7ad0f",
    layout: "8x1",
    title: "Vertical Transportation",
    subtitle: " Hotel + BR",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.85vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/711014?fit=thumb",
    activities: [
      {
        svg: 5478,
        id: "667a3c09-d4b5-46cb-8a38-c5a8503009fa",
        title: "Shaft Clearance",
      },
      {
        svg: 5478,
        id: "366bbfe8-30be-4901-8ee7-b125ca98c39c",
        title: "Installation of Doors",
      },
      {
        svg: 5478,
        id: "cd223cf8-0bff-47b2-ea1d-91eb6261a81a",
        title: "Control equipment’s",
      },
      {
        svg: 5478,
        id: "6081dd8e-b0a8-447d-d59c-6a5389e6e395",
        title: "Machines on the Technical Room",
      },
      {
        svg: 5487,
        id: "9f5b6ab1-5130-42c4-c247-847944425b86",
        title: "Installation of GRIK + Guide Rails + Brackets",
      },
      {
        svg: 5487,
        id: "366bbfe8-30be-4901-8ee7-b125ca98c39c",
        title: "Installation of Doors",
      },
      {
        svg: 5487,
        id: "cd223cf8-0bff-47b2-ea1d-91eb6261a81a",
        title: "Control equipment’s",
      },
      {
        svg: 5487,
        id: "6081dd8e-b0a8-447d-d59c-6a5389e6e395",
        title: "Machines on the Technical Room",
      },
    ],
  },
  {
    id: "ce4e0680-3d7b-40c2-82cc-7b2289d44633",
    layout: "2x2",
    title: "Vertical Transportation",
    subtitle: " Parking Kitchen",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "1.2vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/1003641?fit=thumb",
    activities: [
      {
        svg: 7280,
        id: "667a3c09-d4b5-46cb-8a38-c5a8503009fa",
        title: "Shaft Clearance",
      },
      {
        svg: 7280,
        id: "366bbfe8-30be-4901-8ee7-b125ca98c39c",
        title: "Installation of Doors",
      },
      {
        svg: 7280,
        id: "cd223cf8-0bff-47b2-ea1d-91eb6261a81a",
        title: "Control equipment’s",
      },
      {
        svg: 7280,
        id: "6081dd8e-b0a8-447d-d59c-6a5389e6e395",
        title: "Machines on the Technical Room",
      },
    ],
  },
  {
    id: "d15f57cd-fd2f-43b7-8d6c-8420d2c15645",
    layout: "12x1",
    title: "BIM Coordination",
    subtitle: "ARCI - MEP - PS",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.8vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/705847?fit=thumb",
    activities: [
      {
        svg: 5460,
        id: "11c673f6-a9f2-486f-8979-f79d74b710b8",
        title: "F1 Package - ARC",
      },
      {
        svg: 5460,
        id: "b518d2c9-5d5b-4554-fea0-ec0abc0aa51b",
        title: "F1 Package - MEP",
      },
      {
        svg: 5460,
        id: "14d7af75-8c3c-42a8-d419-f70246f32782",
        title: "F1 Package - PS",
      },
      {
        svg: 5460,
        id: "add4d459-711e-4a06-d1e6-aad9452d7e46",
        title: "F2 Package - MEP",
      },
      {
        svg: 5460,
        id: "fb91fcf2-db72-499a-819d-a3bfb87c9a7b",
        title: "F2 Package - ARC",
      },
      {
        svg: 5460,
        id: "d5ecd20e-60a6-40cc-db9d-a12b27dc5947",
        title: "F2 Package - PS",
      },
      {
        svg: 5460,
        id: "72ec322f-e43c-4aaf-e5e4-d53882c702b0",
        title: "F3 Package - ARC",
      },
      {
        svg: 5460,
        id: "2744b1ae-2caf-4f7e-8f8f-02c15a1b0df0",
        title: "F3 Package - MEP",
      },
      {
        svg: 5460,
        id: "28a12e88-242d-4235-a12f-9b5f1ff7bd6c",
        title: "F3 Package - PS",
      },
      {
        svg: 5460,
        id: "1b944f88-1458-44ea-ea10-174d77bd5505",
        title: "M1 - K&L - ARC",
      },
      {
        svg: 5460,
        id: "34a247a7-8606-4729-d5d8-7256e2370514",
        title: "M1 - K&L - MEP",
      },
      {
        svg: 5460,
        id: "090211d2-a0dd-4e96-c8a7-a4d2620054f3",
        title: "M1 - K&L - PS",
      },
    ],
  },
  {
    id: "05813b92-c136-4410-a0ec-7c1bf667cbac",
    layout: "32x2",
    title: "Office floors - Architectural works tracker",
    subtitle: "Level - 09 to Level - 30",
    projectId: "a616943b-1c84-4169-9dd5-57c49207792e",
    activitiesFontSizeAndUnit: "0.7vh", // the blue title box is 3vh
    previewUrl:
      "/api/v1/api/projects/a616943b-1c84-4169-9dd5-57c49207792e/images/767989?fit=thumb",
    activities: [
      // WALL - BLOCKWORK
      {
        svg: 6609,
        id: "97639c49-e071-4f8e-86ea-24601eb71c36",
        title: "WALL", //ELECTRICAL ROOM (003)
      },
      {
        svg: 6610,
        id: "f5ff09fb-3e93-4c74-f096-24c7dc150404",
        title: " - ", //FIRE FIGHTING LOBBY & CORRIDOR (006 & 008)
      },
      {
        svg: 6611,
        id: "1c0fe397-70d6-45ca-d557-ab5e53fec234",
        title: "BL", //IDF ROOM (004)
      },
      {
        svg: 6612,
        id: "e6cc4666-c621-4757-c684-8cef7b2635e1",
        title: "OC", //ELECTRICAL ROOM (005)
      },
      {
        svg: 6613,
        id: "9e0a4899-aaf7-4aa9-de89-ca5eb837f788",
        title: "K ", //LIFT LOBBY (009)
      },
      {
        svg: 6614,
        id: "87644cc2-91fb-4729-d32a-35f8d7d5b6ab",
        title: "WO", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "5469320a-d8ec-4c08-9dd0-c43e86ab599b",
        title: "RK ", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "68358f4d-8212-414f-be17-66f17af28fc8",
        title: " ", //VEST & JAN ROOM (012 & 013)
      },
      // WALL - PLASTERING & SEALANT
      {
        svg: 6609,
        id: "73b21a3b-1b2a-4f52-a658-20a57fbb5a8b",
        title: "WALL", //ELECTRICAL ROOM (003)
      },
      {
        svg: 6610,
        id: "8983b93d-df52-4059-f070-08607350b503",
        title: " - ", //FIRE FIGHTING LOBBY & CORRIDOR (006 & 008)
      },
      {
        svg: 6611,
        id: "883c2e81-b740-47c8-dba1-f8bef2e10bca",
        title: "STER", //IDF ROOM (004)
      },
      {
        svg: 6612,
        id: "fa62884d-69ec-41dc-974a-259c287ed367",
        title: "RING", //ELECTRICAL ROOM (005)
      },
      {
        svg: 6613,
        id: "8696469b-90d8-4ba5-e1be-be79f14774fc",
        title: "& S", //LIFT LOBBY (009)
      },
      {
        svg: 6614,
        id: "9240a676-bb69-41c4-8ef1-fc62da6a29ba",
        title: "EA", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "f2d89704-0e33-43a7-ad9a-efd425359a0b",
        title: "LA", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "a1459190-4932-4ab1-dfc5-d91b25c1ac05",
        title: "NT", //VEST & JAN ROOM (012 & 013)
      },
      // WALL - GYPSUM PARTITION
      {
        svg: 6609,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: "WALL", //ELECTRICAL ROOM (003)
      },
      {
        svg: 6610,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: " - ", //FIRE FIGHTING LOBBY & CORRIDOR (006 & 008)
      },
      {
        svg: 6611,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: "GYP", //IDF ROOM (004)
      },
      {
        svg: 6612,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: "SUM", //ELECTRICAL ROOM (005)
      },
      {
        svg: 6613,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98",
        title: "  ", //LIFT LOBBY (009)
      },
      {
        svg: 6614,
        id: "b5c7909d-ed2b-4729-d2aa-051b396068c0",
        title: "PAR", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "72e37612-7626-4712-e46f-27cad6992b83",
        title: "TIT", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: "ION", //VEST & JAN ROOM (012 & 013)
      },
      // FLOOR - SCREED & CURING
      {
        svg: 6609,
        id: "b22440c2-99b2-48d3-8659-86cb73df6f83",
        title: "FLO", //ELECTRICAL ROOM (003)
      },
      {
        svg: 6610,
        id: "5f321f0d-e662-4dc7-c7f3-888cef75d303",
        title: "OR -", //FIRE FIGHTING LOBBY & CORRIDOR (006 & 008)
      },
      {
        svg: 6611,
        id: "74a2b4b4-4f0d-4189-d472-9d88cea0775b",
        title: "SC", //IDF ROOM (004)
      },
      {
        svg: 6612,
        id: "45f3eb56-33c0-4637-9ce2-4b34b2bbdeef",
        title: "RED", //ELECTRICAL ROOM (005)
      },
      {
        svg: 6613,
        id: "8fac354e-a011-4af1-cb56-bf7454619744",
        title: " & ", //LIFT LOBBY (009)
      },
      {
        svg: 6614,
        id: "ee328797-5154-4826-d9ec-bb74654ce864",
        title: "CU", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "d21470b1-04d9-48b7-a829-965ffe3e0597",
        title: "RI", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "f507ada2-c956-4910-941e-fedb6c946c21",
        title: "NG", //VEST & JAN ROOM (012 & 013)
      },
      // WALL & FLOOR- TILES
      {
        svg: 6610,
        id: "2e49f2e5-7c10-4bc0-b3b2-b68c8947cb05",
        title: "TILES", //FIRE FIGHTING LOBBY & CORRIDOR (006 & 008)
      },
      {
        svg: 6611,
        id: "28eb509e-a8dc-441e-d69a-b322275c44ce",
        title: "FLOOR", //IDF ROOM (004)
      },
      {
        svg: 6614,
        id: "bc585de9-d4b4-49b9-ec08-12ff75bda656",
        title: "FLOOR", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "06d22c16-a7d0-40d8-96a9-4403c58d93cb",
        title: "FLOOR", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "12c58ba2-fa58-4827-eeb8-9b28c2ebfd14",
        title: "FLOOR", //VEST & JAN ROOM (012 & 013)
      },
      {
        svg: 6614,
        id: "0a0ce9ac-55e3-478b-8c6d-abcaae321004",
        title: "WALL", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "b7518e27-9391-45bf-e322-6705cd592ae6",
        title: "WALL", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "46568a2b-6bff-4bf4-a7cd-f20eb2e0877b",
        title: "WALL", //VEST & JAN ROOM (012 & 013)
      },

      // CEILING - CLOSURE
      {
        svg: 6609,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: "CE", //ELECTRICAL ROOM (003)
      },
      {
        svg: 6610,
        id: "d532bf6c-479a-4fb9-af09-04487adcaa64",
        title: "IL", //FIRE FIGHTING LOBBY & CORRIDOR (006 & 008)
      },
      {
        svg: 6611,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: "ING", //IDF ROOM (004)
      },
      {
        svg: 6612,
        id: "4642eb40-241c-4dca-b608-1f9ff06a2c98", // inactive NODEid, placeholder for N/A activity
        title: " - ", //ELECTRICAL ROOM (005)
      },
      {
        svg: 6613,
        id: "39885738-f63b-4a2e-99e0-a17ca87e4434",
        title: "CL  ", //LIFT LOBBY (009)
      },
      {
        svg: 6614,
        id: "f2c0455b-27a6-46c7-c008-f7ee85f47db0",
        title: "OS", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "f2caeb3a-084a-4eb8-93dc-2b8aef62e881",
        title: "URE", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "d38eeb41-223f-4f4b-9148-e6e399c51ca0",
        title: "WALL", //VEST & JAN ROOM (012 & 013)
      },
      // PAINTING
      {
        svg: 6609,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "PA", //ELECTRICAL ROOM (003)
      },
      {
        svg: 6610,
        id: "7cd9ee0d-c56b-48dd-d98c-eacb5c9bef8a",
        title: "IN", //FIRE FIGHTING LOBBY & CORRIDOR (006 & 008)
      },
      {
        svg: 6611,
        id: "c53b369d-066a-409d-af2b-20c43887b19f",
        title: "TI", //IDF ROOM (004)
      },
      {
        svg: 6612,
        id: "6a1f0b29-115d-481a-a11e-eaa6f62076c9",
        title: "NG", //ELECTRICAL ROOM (005)
      },
      {
        svg: 6613,
        id: "d8a724c8-1f17-45ea-ac1b-2157f152350a",
        title: "-  ", //LIFT LOBBY (009)
      },
      {
        svg: 6614,
        id: "47ccfdc4-0f02-4e52-88de-8de4118a6ed6",
        title: "FI", //MALE TOILET (010)
      },
      {
        svg: 6615,
        id: "1ba2ed2a-aea4-47d7-a676-9291bfa44d19",
        title: "NAL", //FEMALE TOILET (011)
      },
      {
        svg: 6616,
        id: "e9bb4770-5188-4c76-9020-22d846310ca7",
        title: "", //VEST & JAN ROOM (012 & 013)
      },
      // EMPTY
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
      {
        svg: 6608,
        id: "b2a6c8ae-26b8-4f61-e963-0e6b29841b77",
        title: "", //EMPTY 00of10
      },
    ],
  },
];
