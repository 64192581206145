import React from "react";
import styles from "./LoadingIndicator.module.css";

const LoadingIndicator: React.FC = () => {
  return (
    <div className={styles["loading-wrapper"]}>
      <div className={styles["sb-loading"]}>
        <div className={styles["one"]}></div>
        <div className={styles["two"]}></div>
        <div className={styles["three"]}></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
